(function() {
    'use strict';

    angular.module('ionicMessaging', [
        'ionicAlert',
        'ionicData',
        'ionicFilter',
        'ionicFriend',
        'ionicInfiniteScroll',
        'ionicLogin',
        'ionicRequest',
        'ionicSettings',
        'ionicUser',
        'uabDataRefresh',
        'uabDefaultVariable',
        'uabEnvironment',
        'uabMoment',
        'uabPromiseManagement',
        'uabRedirect'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicMessaging').service(
        'ConversationService',
        ConversationService
    );

    ConversationService.$inject = [
        'DefaultVariableService',
        'EnvironmentService',
        'MessageService'
    ];

    function ConversationService(
        DefaultVariableService,
        EnvironmentService,
        MessageService
    ) {
        var ConversationService = this;

        ConversationService.getConversation = getConversation;
        function getConversation(conversation) {
            return MessageService.getConversation(conversation).then(
                function(data) {
                    if (data) {
                        var conversation = false;
                        if (DefaultVariableService.isArray(data.conversations)) {
                            if (data.conversations.length !== 0) {
                                conversation = DefaultVariableService.getObject(
                                    data.conversations[0]
                                );
                            }
                        } else {
                            conversation = DefaultVariableService.getObject(
                                data.conversations
                            );
                        }

                        return conversation;
                    }

                    return false;
                }
            );
        }

        ConversationService.sendMessage = sendMessage;
        function sendMessage(newMessage) {
            if (DefaultVariableService.getInteger(newMessage.conversation_id) === 0) {
                var conversationId = EnvironmentService.get('conversation_id');
                if (conversationId) {
                    newMessage.conversation_id = conversationId;
                }
            }

            return MessageService.sendMessage(newMessage);
        }

        ConversationService.reset = reset;
        function reset() {
           
        }
        
        ConversationService.reset();

        return ConversationService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicMessaging').service('MessageService', MessageService);

    MessageService.$inject = [
        'DataManagementService',
        'DefaultVariableService',
        'EnvironmentService',
        'FriendListService',
        'ListManagementService',
        'LoginService',
        'RequestManagementService',
        'UserService'
    ];

    function MessageService(
        DataManagementService,
        DefaultVariableService,
        EnvironmentService,
        FriendListService,
        ListManagementService,
        LoginService,
        RequestManagementService,
        UserService
    ) {
        var MessageService = this;

        MessageService.addToMessages = addToMessages;
        function addToMessages(messages) {
            if (!DefaultVariableService.isArray(messages)) {
                messages = [
                    messages
                ];
            }

            if (messages.length !== 0) {
                MessageService.conversations.forEach(
                    function (conversation) {
                        if (conversation.id === messages[0].conversation_id) {
                            conversation.messages = DefaultVariableService.getArray(
                                conversation.messages
                            );

                            conversation.messages = conversation.messages.concat(messages);
                        }
                    }
                );
            }
        }

        MessageService.addToConversations = addToConversations;
        function addToConversations(newConversation) {
            var shouldAdd = true;

            var conversationsLength = MessageService.conversations.length;
            for (var i = 0; i < conversationsLength; i++) {
                if (MessageService.conversations[i].id === newConversation.id) {
                    if (DefaultVariableService.isDefined(MessageService.conversations[i].messages)) {
                        newConversation.messages = MessageService.conversations[i].messages;
                    }

                    MessageService.conversations[i] = newConversation;

                    shouldAdd = false;
                }
            }

            if (shouldAdd) {
                MessageService.conversations.unshift(newConversation);
            }
        }

        MessageService.findConversation = findConversation;
        function findConversation(conversationId) {
            var conversationsLength = MessageService.conversations.length;
            for (var i = 0; i < conversationsLength; i++) {
                if (MessageService.conversations[i].id === conversationId) {
                    return MessageService.conversations[i];
                }
            }

            return null;
        }

        MessageService.getConversation = getConversation;
        function getConversation(conversation) {
            if (!DefaultVariableService.isObject(conversation)) {
                var conversationId = EnvironmentService.get('conversation_id');
                if (conversationId) {
                    conversation = {
                        id: conversationId
                    };
                } else {
                    return MessageService.loadDefaultConversation();
                }
            }

            return MessageService.loadConversation(conversation);
        }

        MessageService.getConversations = getConversations;
        function getConversations() {
            return MessageService.loadConversations();
        }

        MessageService.getFriends = getFriends;
        function getFriends() {
            return FriendListService.getFriends();
        }

        MessageService.getList = getList;
        function getList() {
            return ListManager.getList();
        }

        MessageService.getListManager = getListManager;
        function getListManager() {
            return ListManager;
        }

        MessageService.getDefaultRecipient = getDefaultRecipient;
        function getDefaultRecipient() {
            var recipientId = DefaultVariableService.getInteger(
                EnvironmentService.get('recipient_id'),
                0
            );

            if (recipientId && recipientId !== 0) {
                var recipient = {
                    id: recipientId
                };

                return UserService.getUser(recipient);
            }

            return ListManager.success(false);
        }

        MessageService.loadConversation = loadConversation;
        function loadConversation(conversation) {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'conversations');
            options = RequestManagementService.setAction(options, 'get', conversation.id);

            options = RequestManagementService.setParams(
                options,
                [
                    'Messages.Senders',
                    'Messages.Recipients'
                ]
            );

            return ListManager.get(options);
        }

        MessageService.loadConversations = loadConversations;
        function loadConversations(options) {
            options = RequestManagementService.getRequest(options);

            options = RequestManagementService.setModel(options, 'conversations');
            options = RequestManagementService.setAction(options, 'get');

            return ListManager.getMore(options).then(
                function(data) {
                    if (data) {
                        var conversations = DefaultVariableService.getArray(
                            data.conversations
                        );

                        data.canLoadMore = true;

                        var conversationsLength = conversations.length;
                        if (conversationsLength === 0 || conversationsLength === MessageService.conversationCount) {
                            data.canLoadMore = false;
                        }

                        MessageService.conversationCount = conversationsLength;

                        return data;
                    }

                    return false;
                }
            );
        }

        MessageService.loadDefaultConversation =loadDefaultConversation;
        function loadDefaultConversation (){
            return MessageService.getConversations().then(
                function(data) {
                    var conversations = DefaultVariableService.getArray(
                        data.conversations
                    );

                    if (conversations.length === 0) {
                        return { id: 0 };
                    } else {
                        return MessageService.getConversation(conversations[0]);
                    }
                }
            );
        }

        MessageService.sendMessage = sendMessage;
        function sendMessage(message) {
            var senderId = DefaultVariableService.getInteger(
                message.sender_id,
                0
            );

            if (senderId === 0) {
                return LoginService.getActiveUser().then(
                    function(activeUser) {
                        if (activeUser) {
                            message.sender_id = DefaultVariableService.getInteger(
                                activeUser.id
                            );

                            if (message.sender_id !== 0) {
                                return MessageService.sendMessage(message);
                            }
                        }

                        return false;
                    }
                );
            }

            message = MessageService.stripSenderFromRecipients(message);

            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'messages');
            options = RequestManagementService.setAction(options, 'send');

            options = RequestManagementService.setData(options, JSON.stringify(message));

            return DataManagementService.request(options).then(
                function(data) {
                    if (data) {
                        var conversation = DefaultVariableService.convertToArray(data.conversation);
                        if (conversation.length !== 0) {
                            conversation = conversation[0];

                            return MessageService.updateConversation(
                                conversation,
                                message
                            );
                        }
                    }

                    return false;
                }
            );
        }

        MessageService.stripSenderFromRecipients = stripSenderFromRecipients;
        function stripSenderFromRecipients(message) {
            var strippedRecipients = [];

            var recipients = DefaultVariableService.getArray(message.recipients);

            var recipientsLength = recipients.length;
            for (var i = 0; i < recipientsLength; i++) {
                var recipient = DefaultVariableService.getObject(
                    recipients[i]
                );

                var senderId = DefaultVariableService.getInteger(
                    message.sender_id,
                    0
                );

                if (senderId !== recipient.id && senderId !== 0) {
                    strippedRecipients.push(recipients[i]);
                }
            }

            message.recipients = strippedRecipients;

            return message;
        }

        MessageService.updateConversation = updateConversation;
        function updateConversation(conversation, message) {
            if (conversation.id !== 0) {
                if (DefaultVariableService.get(conversation.last_message, 0) !== 0) {
                    message.conversation_id = conversation.last_message.conversation_id;
                    message.recipients = conversation.last_message.recipients;
                    message.senders = conversation.last_message.senders;
                    message.sender_id = conversation.last_message.sender_id;
                }

                conversation.messages = DefaultVariableService.getArray(conversation.messages);
                conversation.messages = ListManagementService.addToList(
                    message,
                    conversation.messages
                );

                ListManager.updateInList(conversation);

                return conversation;
            }

            return false;
        }

        MessageService.reset = reset;
        function reset() {
            ListManager.reset();

            MessageService.conversations = [];

            MessageService.conversationCount = 0;
        }

        var ListManager = ListManagementService.getListManager(
            MessageService.getConversations
        );

        MessageService.reset();

        return MessageService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicMessaging').service(
        'UnreadConversationsService',
        UnreadConversationsService
    );

    UnreadConversationsService.$inject = [
        'DataManagementService',
        'DefaultVariableService',
        'EnvironmentService',
        'MomentService',
        'PromiseManagementService',
        'RequestManagementService',
        'SettingsService'
    ];

    function UnreadConversationsService(
        DataManagementService,
        DefaultVariableService,
        EnvironmentService,
        MomentService,
        PromiseManagementService,
        RequestManagementService,
        SettingsService
    ) {
        var UnreadConversationsService = this;

        UnreadConversationsService.getIsSocial = getIsSocial;
        function getIsSocial() {
            return SettingsService.getSetting('isSocial').then(
                function(isSocial) {
                    return DefaultVariableService.get(
                        isSocial,
                        false
                    );
                }
            );
        }

        UnreadConversationsService.getUnreadCount = getUnreadCount;
        function getUnreadCount(options) {
            var requiredWait = EnvironmentService.get(
                'unreadConversationSeconds',
                0
            );

            var secondsSinceUpdate = MomentService().diff(
                UnreadConversationsService.lastUpdated,
                'seconds'
            );

            if (requiredWait < secondsSinceUpdate) {
                return UnreadConversationsService.loadUnreadCount(options);
            }

            return UnreadConversationsService.unreadCount;
        }

        UnreadConversationsService.loadUnreadCount = loadUnreadCount;
        function loadUnreadCount(options) {
            UnreadConversationsService.lastUpdated = MomentService();

            options = RequestManagementService.getRequest(options);

            options = RequestManagementService.setModel(options, 'messages_recipients');
            options = RequestManagementService.setAction(options, 'unreadCount');

            UnreadConversationsService.unreadCount =  DataManagementService.request(options).then(
                function(data) {
                    if (data) {
                        return DefaultVariableService.getInteger(
                            data.count
                        );
                    }

                    return false;
                }
            );

            return UnreadConversationsService.unreadCount;
        }

        UnreadConversationsService.reset = reset;
        function reset() {
            UnreadConversationsService.lastUpdated = MomentService('2000-01-01');

            UnreadConversationsService.unreadCount = DefaultVariableService.getPromise;
        }

        UnreadConversationsService.reset();

        return UnreadConversationsService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicMessaging').controller('ConversationController', ConversationController);

    ConversationController.$inject = [
        'AlertService',
        'DefaultImageService',
        'DefaultVariableService',
        'ConversationService',
        'RedirectService'
    ];

    function ConversationController(
        AlertService,
        DefaultImageService,
        DefaultVariableService,
        ConversationService,
        RedirectService
    ) {
        var ConversationController = this;

        ConversationController.getUserImage = getUserImage;
        function getUserImage(user) {
            return DefaultImageService.getUserImage(user);
        }

        ConversationController.goInbox = goInbox;
        function goInbox() {
            RedirectService.goTo(
                ConversationController.sref
            );
        }

        ConversationController.goToUser = goToUser;
        function goToUser(user) {
            RedirectService.goTo(
                ConversationController.userSref,
                {
                    userId: user.id
                }
            );
        }

        ConversationController.loadConversation = loadConversation;
        function loadConversation(conversation) {
            conversation = DefaultVariableService.get(
                conversation,
                ConversationController.conversation
            );

            ConversationController.isLoadingConversation = true;

            return ConversationService.getConversation(conversation).then(
                function(conversation) {
                    if (conversation) {
                        ConversationController.conversation = conversation;
                    }

                    return conversation;
                }
            ).finally(
                function() {
                    ConversationController.isLoadingConversation = false;
                }
            );
        }

        ConversationController.sendMessage = sendMessage;
        function sendMessage() {
            ConversationController.isSendingMessage = true;

            ConversationController.newMessage.recipients = DefaultVariableService.getArray(
                ConversationController.conversation.last_message.recipients.concat(
                    [
                        ConversationController.conversation.last_message.senders
                    ]
                )
            );

            ConversationController.newMessage.conversation_id = ConversationController.conversation.id;

            return ConversationService.sendMessage(
                ConversationController.newMessage
            ).then(
                function(conversation) {
                    if (conversation) {
                        AlertService.addMessage('MESSAGE SENT.');

                        ConversationController.resetNewMessage();

                        if (ConversationController.sref) {
                            RedirectService.goTo(
                                ConversationController.sref
                            );
                        }
                    }

                    return conversation;
                }
            ).finally(
                function() {
                    ConversationController.isSendingMessage = false;
                }
            );
        }

        ConversationController.toggleReply = toggleReply;
        function toggleReply() {
            ConversationController.showReply = !ConversationController.showReply;
        }

        ConversationController.reset = reset;
        function reset() {
            ConversationController.conversation = DefaultVariableService.getObject();

            ConversationController.isLoadingConversation = false;

            ConversationController.isSendingMessage = false;

            ConversationController.resetNewMessage();

            ConversationController.showReply = false;

            ConversationController.sref = DefaultVariableService.get(
                ConversationController.sref,
                false
            );

            ConversationController.userSref = DefaultVariableService.get(
                ConversationController.userSref,
                'app.profile'
            );
        }

        ConversationController.resetNewMessage = resetNewMessage;
        function resetNewMessage() {
            ConversationController.newMessage = {};
            ConversationController.newMessage.recipients = [];
        }

        ConversationController.init = init;
        function init() {
            ConversationController.reset();
            ConversationController.loadConversation();
        }

        ConversationController.init();
    }
})();
(function() {
    'use strict';

    angular.module('ionicMessaging').directive('ionicConversation', ionicConversation);

    function ionicConversation() {
        return {
            bindToController: {
                sref:     '@',
                userSref: '@'
            },
            controller:   'ConversationController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:        {},
            template:'<div class="list" data-ng-if="!ctrl.isLoadingConversation"><div class="item" data-ng-if="ctrl.showReply"><label class="item item-input"><textarea data-ng-model="ctrl.newMessage.message"></textarea></label> <button class="button button-full button-positive accept-button" data-ng-click="ctrl.sendMessage()" data-ng-disabled="ctrl.isSendingMessage">{{ ctrl.isSendingMessage ? \'REPLYING\' : \'REPLY\' }}</button> <button class="button button-full button-assertive decline-button" data-ng-click="ctrl.toggleReply()">CANCEL</button></div><div><button class="button button-small button-stable selectedObject" data-ng-click="ctrl.goToUser(ctrl.conversation.last_message.senders)">{{ ctrl.conversation.last_message.senders.full_name }}</button> <button class="button button-small button-stable selectedObject" data-ng-repeat="recipient in ctrl.conversation.last_message.recipients" data-ng-click="ctrl.goToUser(recipient)">{{ recipient.full_name }}</button><div class="black item" data-ng-if="!ctrl.showReply" data-ng-click="ctrl.toggleReply()"><i class="ion ion-reply replyIcon"></i>REPLY</div></div><div class="item item-avatar" data-ng-repeat="message in ctrl.conversation.messages"><img data-ng-src="{{ ctrl.getUserImage(message.senders) }}"><h2>{{ message.senders.username }}</h2><h4>{{ message.created | date }}</h4><div class="row" data-ng-if="message.recipients.length !== 0"></div><div class="item item-text-wrap">{{ message.message }}</div></div><div><button class="button button-block decline-button" data-ng-click="ctrl.goInbox()">BACK</button></div></div><span data-ng-if="ctrl.isLoadingConversation"><ion-spinner class="centerSpinner"></ion-spinner></span>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicMessaging').controller(
        'InboxController',
        InboxController
    );

    InboxController.$inject = [
        'DefaultImageService',
        'DefaultVariableService',
        'MessageService',
        'LoginService',
        'MomentService',
        'RedirectService',
        '$scope'
    ];

    function InboxController(
        DefaultImageService,
        DefaultVariableService,
        MessageService,
        LoginService,
        MomentService,
        RedirectService,
        $scope
    ) {
        var InboxController = this;

        $scope.moment = MomentService();

        $scope.$watch(
            'previewLimit',
            function(previewLimit) {
                InboxController.previewLimit = DefaultVariableService.get(
                    previewLimit,
                    50
                );
            }.bind(InboxController)
        );

        InboxController.clear = clear;
        function clear() {
            MessageService.reset();
            InboxController.reset();
        }

        InboxController.forceRefresh = forceRefresh;
        function forceRefresh() {
            InboxController.clear();
            InboxController.init();
        }

        InboxController.getDate = getDate;
        function getDate(dateField) {
            var momentDate = MomentService(dateField);
            var weekAgo = MomentService().clone().subtract(7, 'days').startOf('day');
            if (momentDate.isBefore(weekAgo)) {
                return MomentService(dateField).format('MMM Do');
            } else {
                return MomentService(dateField).format('dddd');
            }
        }

        InboxController.getUserImage = getUserImage;
        function getUserImage(user) {
            return DefaultImageService.getUserImage(user);
        }

        InboxController.goToConversation = goToConversation;
        function goToConversation(conversation) {
            var conversationId = DefaultVariableService.getInteger(
                conversation.id
            );

            if (conversationId !== 0) {
                RedirectService.goTo(
                    InboxController.conversationSref,
                    {
                        conversationId: conversationId
                    }
                );
            }
        }

        InboxController.goToUser = goToUser;
        function goToUser(user) {
            var userId = DefaultVariableService.getInteger(
                user.id
            );

            if (userId !== 0) {
                RedirectService.goTo(
                    InboxController.userSref,
                    {
                        'userId': userId
                    }
                );
            }
        }

        InboxController.loadConversations = loadConversations;
        function loadConversations() {
            InboxController.isLoadingConversations = true;

            return MessageService.getConversations().then(
                function(data) {
                    InboxController.canLoadMore = false;

                    if (data) {
                        InboxController.conversations = DefaultVariableService.getArray(
                            data.conversations
                        );

                        InboxController.canLoadMore = DefaultVariableService.get(
                            data.canLoadMore,
                            false
                        );
                    }

                    return data;
                }
            ).finally(
                function() {
                    InboxController.isLoadingConversations = false;
                }
            );
        }

        InboxController.reset = reset;
        function reset() {
            InboxController.canLoadMore = true;

            InboxController.conversations = [];

            InboxController.isLoadingConversations = false;

            InboxController.conversationSref = DefaultVariableService.get(
                InboxController.conversationSref,
                'app.conversation'
            );

            InboxController.userSref = DefaultVariableService.get(
                InboxController.userSref,
                'app.profile'
            );
        }

        InboxController.init = init;
        function init() {
            InboxController.reset();
            InboxController.loadConversations();
        }

        LoginService.register(InboxController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicMessaging').directive('ionicInbox', ionicInbox);

    function ionicInbox() {
        return {
            bindToController: {
                conversationSref: '@',
                userSref:         '@'
            },
            controller:   'InboxController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                canLoadMore:            '=',
                conversations:          '=',
                isLoadingConversations: '=',
                onLoadConversations:    '=',
                previewLimit:           '='
            },
            template:'<ion-list><ionic-force-refresh refresh-function="ctrl.forceRefresh"></ionic-force-refresh><ion-item class="item item-avatar" data-ng-class="{ unreadConversation : conversation.unread_messages !== 0 }" data-ng-repeat="conversation in ctrl.conversations"><img class="inbox-image" data-ng-src="{{ ctrl.getUserImage(conversation.last_message.senders) }}" data-ng-click="ctrl.goToConversation(conversation)"> <button class="button button-small button-stable selectedObject" data-ng-click="ctrl.goToUser(conversation.last_message.senders)">{{ conversation.last_message.senders.full_name }}</button> <button class="button button-small button-stable selectedObject" data-ng-repeat="recipient in conversation.last_message.recipients" data-ng-click="ctrl.goToUser(recipient)">{{ recipient.full_name }}</button><p data-ng-click="ctrl.goToConversation(conversation)">{{ conversation.last_message.message | limitTo : previewLimit : 0 }}</p><p class="ionicInboxTime" data-ng-click="ctrl.goToConversation(conversation)">{{ ctrl.getDate(conversation.last_message.created) }}</p></ion-item><div class="bar bar-stable" data-ng-if="!ctrl.isLoadingConversations && ctrl.conversations.length === 0"><h1 class="title">NO MESSAGES</h1></div><div class="bar bar-stable" data-ng-if="!ctrl.canLoadMore && ctrl.conversations.length !== 0"><h1 class="title">NO MORE MESSAGES</h1></div><ionic-infinite-scroll data-ng-if="ctrl.canLoadMore && !ctrl.isLoadingConversations" can-load-more="ctrl.canLoadMore" immediate-check="false" is-loading="ctrl.isLoadingConversations" load-more="ctrl.loadConversations"></ionic-infinite-scroll></ion-list>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicMessaging').controller(
        'NewConversationController',
        NewConversationController
    );

    NewConversationController.$inject = [
        'AlertService',
        'DefaultVariableService',
        'MessageService',
        '$scope',
        'RedirectService'
    ];

    function NewConversationController(
        AlertService,
        DefaultVariableService,
        MessageService,
        $scope,
        RedirectService
    ) {
        var NewConversationController = this;

        $scope.$watch(
            'previousSref',
            function(previousSref) {
                NewConversationController.previousSref = DefaultVariableService.get(
                    previousSref,
                    'app.inbox'
                );
            }
        );

        NewConversationController.clear = clear;
        function clear() {
            MessageService.reset();
            NewConversationController.reset();
        }

        NewConversationController.loadDefaultRecipient = loadDefaultRecipient;
        function loadDefaultRecipient() {
            return MessageService.getDefaultRecipient().then(
                function(recipient) {
                    if (recipient) {
                        NewConversationController.newMessage.recipients.push(recipient);
                    }

                    return recipient;
                }
            );
        }

        NewConversationController.loadRecipients = loadRecipients;
        function loadRecipients() {
            NewConversationController.isLoadingRecipients = true;

            return MessageService.getFriends().then(
                function(recipients) {
                    if (recipients) {
                        NewConversationController.recipients = recipients;
                    }

                    return recipients;
                }
            ).finally(
                function() {
                    NewConversationController.isLoadingRecipients = false;
                }
            );
        }

        NewConversationController.selectRecipients = selectRecipients;
        function selectRecipients(recipients) {
            NewConversationController.newMessage.recipients = DefaultVariableService.getArray(
                recipients
            );
        }

        NewConversationController.reset = reset;
        function reset() {
            NewConversationController.isLoadingRecipients = false;

            NewConversationController.isSendingMessage = false;

            NewConversationController.recipients = [];

            NewConversationController.resetNewMessage();
        }

        NewConversationController.resetNewMessage = resetNewMessage;
        function resetNewMessage() {
            NewConversationController.newMessage = {};
            NewConversationController.newMessage.recipients = [];
        }

        NewConversationController.sendMessage = sendMessage;
        function sendMessage() {
            NewConversationController.isSendingMessage = true;

            return MessageService.sendMessage(
                NewConversationController.newMessage
            ).then(
                function(response) {
                    if (response) {
                        AlertService.addMessage('MESSAGE SENT.');

                        NewConversationController.resetNewMessage();

                        RedirectService.goTo(
                            NewConversationController.previousSref
                        );
                    }

                    return response;
                }
            ).finally(
                function() {
                    NewConversationController.isSendingMessage = false;
                }
            );
        }

        NewConversationController.init = init;
        function init() {
            NewConversationController.reset();
            NewConversationController.loadDefaultRecipient();
            NewConversationController.loadRecipients();
        }

        NewConversationController.init();
    }
})();
(function() {
    'use strict';

    angular.module('ionicMessaging').directive('ionicNewConversation', ionicNewConversation);

    function ionicNewConversation() {
        return {
            controller:   'NewConversationController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                previousSref:     '='
            },
            template:'<ion-list><label class="item item-input item-stacked-label row"><span class="input-label">RECIPIENT(S)</span><ionic-friend-filter on-select="ctrl.selectRecipients" target="ctrl.newMessage.recipients"></ionic-friend-filter></label><div data-ng-if="!ctrl.isLoadingRecipients"><label class="item item-input"><textarea data-ng-model="ctrl.newMessage.message" placeholder="MESSAGE">\n                {{ ctrl.newMessage.message }}\n            </textarea></label> <button class="button button-full button-positive accept-button" data-ng-click="ctrl.sendMessage()" data-ng-disabled="ctrl.isSendingMessage">{{ ctrl.isSendingMessage ? \'SENDING...\' : \'SEND\' }}</button></div></ion-list>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicMessaging').controller(
        'UnreadConversationsController',
        UnreadConversationsController
    );

    UnreadConversationsController.$inject = [
        'DefaultVariableService',
        'UnreadConversationsService',
        'LoginService',
        'RedirectService'
    ];

    function UnreadConversationsController(
        DefaultVariableService,
        UnreadConversationsService,
        LoginService,
        RedirectService
    ) {
        var UnreadConversationsController = this;

        UnreadConversationsController.clear = clear;
        function clear() {
            UnreadConversationsService.reset();
            UnreadConversationsController.reset();
        }

        UnreadConversationsController.goToInbox = goToInbox;
        function goToInbox() {
            RedirectService.goTo(
                UnreadConversationsController.sref
            );
        }

        UnreadConversationsController.loadIsSocial = loadIsSocial;
        function loadIsSocial() {
            return UnreadConversationsService.getIsSocial().then(
                function(isSocial) {
                    UnreadConversationsController.isSocial = isSocial;

                    return isSocial;
                }
            );
        }

        UnreadConversationsController.loadUnreadCount = loadUnreadCount;
        function loadUnreadCount() {
            return UnreadConversationsService.getUnreadCount().then(
                function(unreadCount) {
                    if (unreadCount) {
                        UnreadConversationsController.unreadCount = unreadCount;
                    }

                    return unreadCount;
                }
            );
        }

        UnreadConversationsController.reset = reset;
        function reset() {
            UnreadConversationsController.isSocial = false;

            UnreadConversationsController.sref = DefaultVariableService.get(
                UnreadConversationsController.sref,
                'app.unreadConversations'
            );

            UnreadConversationsController.unreadCount = 0;
        }

        UnreadConversationsController.init = init;
        function init() {
            UnreadConversationsController.reset();

            UnreadConversationsController.loadIsSocial();
            UnreadConversationsController.loadUnreadCount();

            RedirectService.registerBeforeInternalRedirect(
                UnreadConversationsController.loadUnreadCount
            );
        }

        LoginService.register(UnreadConversationsController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicMessaging').directive(
        'ionicUnreadConversations',
        ionicUnreadConversations
    );

    function ionicUnreadConversations() {
        return {
            bindToController: {
                sref: '@'
            },
            controller:   'UnreadConversationsController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                unreadCount: '='
            },
            template:'<a data-ng-if="ctrl.isSocial && ctrl.unreadCount !== 0" class="animated bounceIn button icon ion-email primary-color" data-ng-click="ctrl.goToInbox()"><span class="icon-badge">{{ ctrl.unreadCount }}</span></a>'
        };
    }
})();